import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { above } from '../../../styles'
import useWebsiteSettings from '../../../utils/build-helpers/useWebsiteSettings'

export default ({ alt }) => {
  const { logo } = useWebsiteSettings()

  if (!logo) {
    return <div>logo</div>
  }

  return <Logo fadeIn={false} loading="eager" fluid={logo.fluid} alt={alt} />
}

const Logo = styled(Img)`
  width: 78px;
  max-height: 35px;

  img {
    object-fit: contain !important;
  }

  ${above.desktop`
    width: 205px;
    max-height: 84px;
  `};
`
