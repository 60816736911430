import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import sanitize from 'sanitize-filename'
import { usePageContext } from '../context/PageContext'
import AppLinks from '../components/AppLinks/index.ts'

export const ContentfulLink = ({ linkData = {}, className, ariaLabel, onClick, children }) => {
  const { externalUrl, appLinkUrl, content, asset, assetSlug, anchor, accessibleDescription } = linkData
  const { allPages } = usePageContext()

  const commonProps = {
    className,
    title: accessibleDescription,
    onClick,
    'aria-label': ariaLabel,
  }

  if (content) {
    const link = `/${allPages[content.id]}`.replace('//', '/')
    return (
      <GatsbyLink to={link} {...commonProps}>
        {children}
      </GatsbyLink>
    )
  }

  if (asset) {
    const slug = assetSlug ? `${sanitize(assetSlug).replace(/ /g, '-')}.pdf` : asset.file.fileName
    const prefix = [undefined, '/'].includes(process.env.GATSBY_PATH_PREFIX) ? '' : `/${process.env.GATSBY_PATH_PREFIX}`
    return (
      <a href={`${prefix}/docs/${slug}`} target="_blank" rel="noreferrer" {...commonProps}>
        {children}
      </a>
    )
  }

  if (externalUrl) {
    return (
      <a href={externalUrl} target="_blank" rel="noreferrer" {...commonProps}>
        {children}
      </a>
    )
  }

  if (appLinkUrl) {
    return <AppLinks url={appLinkUrl} linkTitle={accessibleDescription} />
  }

  if (anchor) {
    return (
      <a href={`#${anchor}`} {...commonProps}>
        {children}
      </a>
    )
  }

  return null
}
