import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  // base: '0px', // added by chakra, mobile
  mobile: '640px',
  tablet: '768px',
  desktop: '1024px',
  desktopLarge: '1440px',
})

export default breakpoints
