import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../../utils/build-helpers/useWebsiteSettings'
import { ContentfulLink } from '../../../utils/link'
import LanguageSelector from '../../languageSelector/languageSelector'
// import avatarIcon from '../../../assets/icons/avatar.svg'
import { usePageContext } from '../../../context/PageContext'
import { above, costaTextBold } from '../../../styles'
// import { showCorrectAccountOptions } from '../../../helpers/loginHelper'

export default ({ setIsMenuOpen, location }) => {
  const { intl, locale } = usePageContext()
  const { mainNavigation } = useWebsiteSettings()
  const languages = Object.entries(intl).map(([key]) => key)

  // const checkActiveState = (link, path) => {
  //   const isJapanSite = process.env.GATSBY_BUDDY_PIPELINE === 'japan'

  //   if (link.htmlTarget || isJapanSite) {
  //     return false
  //   }

  //   if (link.internalLink && path.includes(encodeURIComponent(link.internalLink.slug))) {
  //     return true
  //   }

  //   if (
  //     link.internalLink?.parentAssembly &&
  //     path.includes(encodeURIComponent(link.internalLink?.parentAssembly?.slug))
  //   ) {
  //     return true
  //   }

  //   if (link.externalLink && path.includes(`/${link.externalLink.split('/')[3]}/`)) {
  //     return true
  //   }

  //   return false
  // }

  const isDefaultLocale = locale === process.env.GATSBY_DEFAULT_LOCALE
  const pageSlug = location?.pathname // remove slash prefix and locale

  const { allPages } = usePageContext()

  return (
    <MainNav>
      <ul>
        {mainNavigation?.links?.map(navItem => {
          let linkSlug = allPages[navItem?.content?.id] || ''
          linkSlug = linkSlug[0] === '/' ? linkSlug.substring(1) : linkSlug // remove slash prefixes
          linkSlug = linkSlug.split('/')[isDefaultLocale ? 0 : 1] // remove locale

          return (
            <Links
              key={navItem.id}
              showActive={linkSlug && pageSlug?.includes(encodeURIComponent(linkSlug.substring(1)))}
              onClick={() => setIsMenuOpen(false)}
              onKeyPress={() => setIsMenuOpen(false)}
            >
              <ContentfulLink linkData={navItem}>{navItem.title}</ContentfulLink>
            </Links>
          )
        })}
        {languages.length > 1 && (
          <LanguageSelectorContainer>
            <LanguageSelector location={location} />
          </LanguageSelectorContainer>
        )}
      </ul>
      {/* {process.env.GATSBY_UK_SITE === 'true' && (
        <AccountManagement className={isMenuOpen && 'menuOpen'}>
          <AvatarIcon src={avatarIcon} />
          {typeof document !== `undefined` && showCorrectAccountOptions(document)}
        </AccountManagement>
      )} */}
    </MainNav>
  )
}

const LanguageSelectorContainer = styled.div`
  display: block;
  background-color: #bd0d43;
  margin: 80px 0;

  li {
    width: auto !important;
    margin: 0 !important;
  }

  ${above.tabletLarge`
    display: none;
  `}
`

const MainNav = styled.nav`
  display: none;
  ${above.tabletLarge`
  
    display: block;
  `}
  width: 100%;
  line-height: 1.15;
`

const Links = styled.li`
  display: inline-block;
  color: white;
  margin: 0 16px;
  ${costaTextBold};
  font-size: 16px;

  &:last-child {
    margin-right: 0;
  }

  ${above.desktop`
    font-size: 20px;
  `}
  > a {
    border-bottom: ${props => props.showActive && '2px solid white'};
  }
`

// const AccountManagement = styled.div`
//   background-color: ${color.lightRed};
//   position: absolute;
//   bottom: 74px;
//   left: 0px;
//   width: 100%;
//   padding: 38px 0px;
//   background: ${color.lightRed};
//   display: none;
//   &.menuOpen {
//     display: block;
//   }
//   ${above.tabletLarge`
//   display:none;
//   `}
//   > a {
//     font-size: 25px;
//     ${costaTextBold};
//     color: ${color.white};
//   }
//   > a:nth-child(3n) {
//     display: block;
//     padding-left: 64px;
//     padding-top: 15px;
//   }
// `

// const AvatarIcon = styled.img`
//   display: inline-block;
//   ${above.tabletLarge`
//     display:none;
//   `}
//   overflow: hidden;
//   margin-left: 36px;
//   margin-right: 11px;
//   width: 17px;
//   height: 21px;
// `
