import React, { useState, useLayoutEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import useMicrocopy from '@costa-coffee/gatsby-theme-cw-store-locator/src/hooks/useMicrocopy'

import { color, above, costaTextBold, costaText } from '../../styles'
import { getMicrocopy } from '../../utils/microcopy'
import { usePageContext } from '../../context/PageContext'
import useLocaleList from '../../helpers/useLocaleList'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

const extractLocaleFromUrl = location => {
  return location.pathname.match(/\/[a-z]{2}/) ? location.pathname.match(/\/[a-z]{2}/)[0].replace(/\//g, '') : defaultLocale
}

// todo: update this with the new link component
const LanguageSelector = ({ location }) => {
  const { locale, intl } = usePageContext()
  const locales = useLocaleList()
  const selectorArray = Object.entries(locales)
  const [active, setActive] = useState(false)
  const selecterRef = useRef(null)
  const handleClickOutside = event => {
    if (selecterRef.current && !selecterRef.current.contains(event.target)) {
      if (active === true) setActive(!active)
    }
  }
  const onClick = () => setActive(!active)
  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const selectedItem = selectorArray.find(element => {
    return element[1].code.substring(0, 2) === extractLocaleFromUrl(location)
  })
  const selectedItemIndex = selectorArray.findIndex(element => {
    return element[1].code.substring(0, 2) === extractLocaleFromUrl(location)
  })
  if (selectedItem !== undefined) {
    selectorArray.splice(selectedItemIndex, 1)
    selectorArray.unshift(selectedItem)
  }

  const data = useMicrocopy(locale)

  return (
    <DropdownContainer data-cy="language-selector" ref={selecterRef} onClick={() => onClick()}>
      <Title>{getMicrocopy({ key: 'languageSelector.Title', data })}</Title>
      <LocalesContainer>
        {selectorArray.map((translation, index) => (
          <ListItem key={translation[0]} data-cy="language-selector_item" active={active} selected={index === 0} role="button">
            <StyledLink
              className={`locale-link ${index === 0 ? 'selected' : ''}`}
              data-cy="language-selector_link"
              onKeyDown={e => {
                // disable enter key on the top item
                if (e.key === 'Enter' && index === 0) {
                  e.preventDefault()
                  setActive(!active)
                }
              }}
              to={intl[translation[1].code] === '/' ? '/' : `/${intl[translation[1].code]}${location.search}`}
            >
              {translation[1].code.substring(0, 2).toUpperCase()}
            </StyledLink>
            {index === 0 && <Arrow active={active} />}
          </ListItem>
        ))}
      </LocalesContainer>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  right: 0;
  padding: 24px 16px 12px 16px;
  z-index: 1;
  line-height: 1.15;
  user-select: none;

  ${above.tabletLarge`
    position: absolute;
    padding: 12px 16px;
    cursor: pointer;
  `}
`

const LocalesContainer = styled.ul`
  display: flex;
  margin: 12px 0;
  width: 100%;
  justify-content: center;

  ${above.tabletLarge`
    display: block;
    flex-direction: column;
    margin: 16px 0 0 0;
`};

  ${above.desktop`
    margin: 12px 0;
`};
`

const Title = styled.h3`
  ${costaTextBold};
  color: ${color.white};
  text-align: center;
  margin: 0;
  width: 100%;

  ${above.tabletLarge`
    display: none;
  `};
`

const ListItem = styled.li`
  color: ${color.white};
  margin: 0 16px;
  ${costaTextBold};
  font-size: 16px;
  align-items: center;
  width: 80px;
  text-align: center;
  display: flex;

  .locale-link {
    ${costaText};

    ${above.tabletLarge`
      font-size: 28px;
      ${costaTextBold};
  `}
  }

  ${above.tabletLarge`
    display: none;
    ${props =>
      props.active &&
      css`
        display: flex;
        background-color: ${color.lightRed};
      `}
  
  `}

  &:last-child {
    span {
      visibility: hidden;
    }
  }

  &:first-child {
    ${above.tabletLarge`
      display: flex;  
    `}
  }

  ${props =>
    props.selected &&
    css`
      display: flex;
      a {
        pointer-events: none;
        ${costaTextBold};
      }
    `}

  ${above.desktop`
    font-size: 30px;
    margin: 0;
  `}
`

const StyledLink = styled(Link)`
  padding: 8px 8px;
  width: 100%;
  text-align: left;

  &.selected {
    ${costaTextBold}
  }
`

const Arrow = styled.span`
  display: none;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffffff;
  position: relative;
  right: 0;
  transition: transform 0.3s;
  transform: rotate(180deg);
  margin-right: 12px;
  ${props =>
    props.active &&
    css`
      transform: rotate(0deg);
    `}
  ${above.tabletLarge`
    display: inline;
  `}
`

export default LanguageSelector
