export default {
  /* Old colours
  alabaster: '#fafafa',
  brightRed: '#b51144',
  costaOrange: '#ce4b27',
  costaRed: '#6d1f37',
  darkGray: '#404042',
  darkRed: '#91022E',
  disabledGray: '#9b9b9b',
  empress: '#747474',
  focusBlue: '#0066ff',
  lightGray: '#dddddd',
  lightPink: '#ffdddd',
  lightRed: '#cf1d1a',
  mediumGray: '#9e9e9e',
  offWhite: '#f4f3ef',
  redPink: '#bf0341',
  successGreen: '#4e8342',
  whiteSmoke: '#f6f6f6',
  black: '#000000',
  white: '#FFFFFF',
  */

  alertError: '#ce0000',
  burntOrange: '#ce4b27',
  costaRed: '#6d1f37',
  greyCore: '#989898',
  greyDark: '#696969',
  greyDarker: '#222322',
  greyLight: '#f0f0f0',
  lightGold: '#d4cdbd', // stamp card empty stamp when updated - secondaryCoreLighter: '#c09fa9',
  lightRed: '#b51144',
  offWhite: '#f4f3ef',
  rebrandBlue: '#b0ddf5',
  rebrandBlueTint: '#bfe3f6',
  secondaryCore: '#cfa8ca',
  secondaryCoreLighter: '#c09fa9',
  secondaryCoreTint: '#d8b8d4',
  successGreen: '#2a7b48',
  tertiaryCore: '#f58e90',
  tertiaryCoreTint: '#f6a4a5',
  white: '#ffffff',

  // TODO as part of the rebrand, the above will be removed in favour of the new colours below. We will need to update any references to the old colours...

  transparent: 'transparent',
  current: 'currentColor',

  brand: {
    brandRed: '#6D1F37',
    white: '#FFFFFF',
    slateGrey: '#404042',
    lightRed: '#B51144',
  },
  ui: {
    burntOrange: '#CE4B27',
    cloudlessBlue: '#B0DDF5',
    nightfallLilac: '#CFA8CA',
    sunsetCoral: '#EE7D80',
    freshGreen: '#32AC70',
    vibrantRed: '#E52F25',
  },
  grey: {
    disabledGrey: '#9B9B9B',
    buttonGrey: '#747474',
    buttonLightGrey: '#DDDDDD',
    offWhite: '#F4F3EF',
  },
}
