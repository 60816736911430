export const color = {
  alertError: '#ce0000',
  burntOrange: '#ce4b27',
  costaRed: '#6d1f37',
  greyCore: '#989898',
  greyDark: '#696969',
  greyDarker: '#222322',
  greyLight: '#f0f0f0',
  lightGold: '#d4cdbd',
  lightRed: '#b51144',
  offWhite: '#f4f3ef',
  rebrandBlue: '#b0ddf5',
  rebrandBlueTint: '#bfe3f6',
  secondaryCore: '#cfa8ca',
  secondaryCoreLighter: '#c09fa9',
  secondaryCoreTint: '#d8b8d4',
  successGreen: '#2a7b48',
  tertiaryCore: '#f58e90',
  tertiaryCoreTint: '#f6a4a5',
  white: '#ffffff',
}
