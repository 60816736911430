/* eslint-disable no-nested-ternary */
import React from 'react'
import ReactPlayer from 'react-player'
import { chakra } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import GatsbyImage from 'gatsby-image'

import RichText from '../richText'
import LinkCta from '../linkCta'
import { color } from '../../styles/colors'

import { WaveBorder } from './waveBorder'

const BlockPromoCampaign = ({
  style,
  waveBorder,
  imageAlignment,
  campaignStyleBgColour,
  campaignStyleTextColour,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
}) => {
  const mediaType = image.file.contentType

  const textColour =
    campaignStyleTextColour && campaignStyleTextColour === 'Costa Red'
      ? color.costaRed
      : campaignStyleTextColour === 'Grey'
      ? color.greyDarker
      : color.white

  const ChakraGatsbyImage = chakra(GatsbyImage)
  const ChakraLinkCta = chakra(LinkCta)
  const ChakraWaveBorder = chakra(WaveBorder)
  const ChakraVideoWrapper = chakra('div', {
    position: 'relative',
    top: '0',
    bottom: '0',
    w: '100%',
    h: '100%',
    overflow: 'hidden',
  })
  return (
    <Box
      as="article"
      my={10}
      bg={campaignStyleBgColour || 'white'}
      mx="auto"
      d="flex"
      flexWrap={{
        tablet: 'nowrap',
      }}
      flexDir={{
        // image on top when the wave is enabled
        base: waveBorder ? 'column-reverse' : 'column',
        tablet: imageAlignment,
      }}
      maxW={{
        desktopLarge: '1392px',
      }}
      overflow="hidden"
      position="relative"
    >
      <Box
        w={{
          base: '100%',
          tablet: '50%',
        }}
        color={textColour}
        bg={campaignStyleBgColour}
        px={{
          base: '10%',
          tablet: '5%',
          desktop: '6.5%',
        }}
        py={{
          base: 6,
          mobile: 12,
          tablet: 10,
        }}
        d="flex"
        position="relative"
        justifyContent="center"
        flexDir="column"
        minH={{
          base: '300px',
          tablet: '512px',
        }}
        textAlign="center"
        sx={{
          'h1, h2, h3, h4, h5, h6': {
            color: textColour,
            mb: 3,
            w: '100%',
          },
          p: {
            mb: 0,
            color: textColour,
            w: '100%',
            px: { desktop: 4 },
          },
          a: {
            mb: 0,
          },
        }}
      >
        {waveBorder && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            left={{
              base: '0',
              tablet: imageAlignment === 'row-reverse' ? '14px' : 'initial',
            }}
            right={{
              base: '0',
              tablet: imageAlignment === 'initial' ? '14px' : 'initial',
            }}
            top={{
              base: '14px',
              tablet: 'initial',
            }}
            transform={{
              tablet: imageAlignment === 'row-reverse' ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            zIndex="1"
          >
            <ChakraWaveBorder
              position="absolute"
              zIndex="1"
              transform={{
                base: 'rotate(90deg)',
                tablet: 'rotate(0deg)',
              }}
              color={campaignStyleBgColour || 'white'}
            />
          </Box>
        )}
        <RichText data={content} p="relative" zIndex="2" />
        {primaryAction && (
          <ChakraLinkCta
            mt={2}
            link={primaryAction}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="center"
            customColor={textColour || null}
            customLinkColor={textColour || null}
            sx={{
              a: {
                mb: '0 !important',
              },
            }}
          >
            {primaryActionLabel || primaryAction.title}
          </ChakraLinkCta>
        )}
        {secondaryAction && (
          <ChakraLinkCta
            mt={2}
            link={secondaryAction}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="center"
            customColor={textColour}
            customLinkColor={textColour || null}
            sx={{
              a: {
                mb: '0 !important',
              },
            }}
          >
            {secondaryActionLabel || secondaryAction.title}
          </ChakraLinkCta>
        )}
      </Box>
      {mediaType.includes('image') && (
        <ChakraGatsbyImage
          fluid={image.fluid}
          alt={image.description}
          promoStyle={style}
          imageAlignment={imageAlignment}
          w={{
            base: 'auto',
            tablet: '50%',
          }}
          m="0"
          minH={{
            base: '300px',
            tablet: '512px',
          }}
          sx={{
            img: {
              mb: '0',
            },
          }}
        />
      )}
      {mediaType.includes('video') && (
        <Box
          d="flex"
          minH={{
            base: '73px',
            tablet: '512px',
          }}
          position="relative"
          w={{
            tablet: '50%',
          }}
          h={{
            tablet: 'auto',
          }}
          sx={{
            video: {
              display: 'block',
              'object-fit': 'cover',
            },
          }}
        >
          <ReactPlayer url={image.file.url} playsinline playing loop muted wrapper={ChakraVideoWrapper} height="100%" width="100%" />
        </Box>
      )}
    </Box>
  )
}

export default BlockPromoCampaign
