import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ConsentBannerWrapper from './components/consentBannerWrapper/consentBannerWrapper'
import LoadAdobe from './utils/LoadAdobe'

const extractLocaleFromUrl = location =>
  location.pathname.match(/\/[a-z]{2}\//) ? location.pathname.match(/\/[a-z]{2}\//)[0].replace(/\//g, '') : undefined

const LoadConsent = ({ element, props, loadOneTrust }) => {
  const { pageContext, location } = props
  const [allowTracking, setAllowTracking] = useState(false)
  const { allContentfulLocale } = useStaticQuery(graphql`
    {
      allContentfulLocale {
        nodes {
          code
          default
        }
      }
    }
  `)

  const retrievedLocale =
    allContentfulLocale.nodes.find(locale => locale.code === extractLocaleFromUrl(location)) ||
    allContentfulLocale.nodes.find(locale => locale.default)

  return (
    <>
      {(loadOneTrust || allowTracking) && <LoadAdobe oneTrustEnabled={loadOneTrust} />}
      {!loadOneTrust && <ConsentBannerWrapper setAllowTracking={setAllowTracking} locale={pageContext.locale || retrievedLocale.code} />}
      {element}
    </>
  )
}

export default LoadConsent
