import React from 'react'
import { usePageContext } from '../../context/PageContext'
import { renderRichText } from '../../utils/richText'

const RichText = ({ data }) => {
  const { allPages } = usePageContext()
  return (data?.raw && <>{renderRichText(data, allPages)}</>) || <></>
}

export default RichText
