import { extendTheme } from '@chakra-ui/react'

import breakpoints from './foundations/breakpoints'
import colors from './foundations/colors'
import spacing from './foundations/spacing'
import typography from './foundations/typography'

export default extendTheme({
  breakpoints,
  colors,
  space: spacing,
  ...typography,

  components: {},

  styles: {
    global: () => ({
      body: {
        color: colors.greyDarker,
      },
    }),
  },
})
