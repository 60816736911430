import React from 'react'
import { TableContainer, Table, Tr, Td, Th } from '@chakra-ui/react'

const ChakraTable = ({ children }) => (
  <TableContainer marginBottom={4} marginTop={2} whiteSpace="initial">
    <Table border="1px" borderColor="brand.brandRed">
      {children}
    </Table>
  </TableContainer>
)

const ChakraTableRow = ({ children }) => <Tr>{children}</Tr>

const ChakraTableCell = ({ children }) => (
  <Td borderRight="1px" borderBottomColor="brand.brandRed" borderRightColor="brand.brandRed">
    {children}
  </Td>
)

const ChakraTableHeaderCell = ({ children }) => (
  <Th
    textTransform="initial"
    borderRight="1px"
    borderBottomColor="brand.brandRed"
    borderRightColor="brand.brandRed"
    sx={{
      '> p': {
        margin: 0,
        fontFamily: '"Costa Display Wave Bold"',
      },
    }}
  >
    {children}
  </Th>
)

export { ChakraTable, ChakraTableRow, ChakraTableCell, ChakraTableHeaderCell }
