import React from 'react'
import { TrackJS } from 'trackjs'
import { version } from './package.json'
import { PageContextProvider } from './src/context/PageContext'
import LoadConsent from './src/loadConsent'

const token = process.env.GATSBY_TRACKJS_TOKEN || ''
const application = process.env.GATSBY_TRACKJS_APPLICATION || ''
const loadOneTrust = process.env.GATSBY_LOAD_ONETRUST === 'true'

if (application) {
  TrackJS.install({
    token,
    application,
    version,
  })
}

export const wrapPageElement = page => (
  <PageContextProvider pageContext={page.props.pageContext}>
    <LoadConsent {...page} loadOneTrust={loadOneTrust} />
  </PageContextProvider>
)
