/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unneeded-ternary */

import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import { color, above, headingOneWave } from '../../../styles'
import RichText from '../../richText'
// import richTextRenderer from '../../../utils/richTextRenderer'

export default ({
  content: {
    mobileBackground,
    tabletBackground,
    background,
    megaHero,
    content,
    heroTextColour,
    mobileTextAlignment,
    desktopTextAlignment,
    mobileGradient,
  },
}) => {
  let mobileImage
  let tabletImage
  let image
  if (!background?.file?.contentType.includes('video')) {
    mobileImage = mobileBackground ? mobileBackground.fluid?.src : background?.fluid?.src
    tabletImage = tabletBackground ? tabletBackground.fluid?.src : background?.fluid?.src
    image = background?.fluid?.src
  }

  const mobileGradientEnabled = mobileGradient === 'True'
  const [isMobile, setisMobile] = useState(false)
  const videoSrc = isMobile ? mobileBackground?.file?.url : background?.file?.url
  const hideInMobile = mobileBackground ? false : true
  const selectedTextColour = heroTextColour === 'Costa Red' ? color.costaRed : color.white

  const HeroContent = () => (
    <>
      <Gradient hideInMobile={hideInMobile} />
      <Wrapper hideInMobile={hideInMobile} megaHero={megaHero}>
        <Content
          megaHero={megaHero}
          heroTextColour={selectedTextColour}
          mobileTextAlignment={mobileTextAlignment}
          desktopTextAlignment={desktopTextAlignment}
        >
          <RichText data={content} />
        </Content>
      </Wrapper>
      {mobileGradientEnabled && <BottomGradient />}
    </>
  )

  if (background?.file?.contentType.includes('video')) {
    useEffect(() => {
      function updateWidth() {
        setisMobile(window.innerWidth < 768)
      }
      window.addEventListener('resize', updateWidth)
      updateWidth()
      return () => window.removeEventListener('resize', updateWidth)
    }, [isMobile])

    return (
      <VideoHero megaHero={megaHero}>
        <ReactPlayer url={videoSrc} playsinline playing loop muted wrapper={Video} megaHero={megaHero} height="100%" width="100%" />
        <HeroContent />
      </VideoHero>
    )
  }

  return (
    <Hero hideInMobile={hideInMobile} mobileImage={mobileImage} tabletImage={tabletImage} image={image} megaHero={megaHero}>
      <HeroContent />
    </Hero>
  )
}

const Hero = styled.div`
  width: 100%;
  height: ${p => (p.hideInMobile ? '80px' : '448px')};
  background-size: cover;
  background-position: center;
  background-color: ${p => (p.hideInMobile ? `${color.costaRed}` : `transparent`)};
  background-image: ${p => (p.hideInMobile ? `none` : `url(${p.mobileImage})`)};

  ${above.tablet`
    background-image: url(${p => p.tabletImage});
    height: ${p => (p.megaHero ? 800 : 384)}px;
  `}

  ${above.tabletLarge`
  background-image: url(${p => p.image});
    height: ${p => (p.megaHero ? 584 : 376)}px;
  `}

  ${above.desktop`
    height: ${p => (p.megaHero ? 680 : 464)}px;
  `}
`
const VideoHero = styled.div`
  width: 100%;
  height: 448px;

  ${above.tablet`
    background-color: rgba(0, 0, 0, 0.15);
    height: ${p => (p.megaHero ? 800 : 384)}px;
  `}

  ${above.tabletLarge`
    background-color: transparent;
    height: ${p => (p.megaHero ? 584 : 376)}px;
  `}

  ${above.desktop`
    height: ${p => (p.megaHero ? 680 : 464)}px;
  `}
`

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 448px !important;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  overflow: hidden;

  ${above.tablet`
    height: ${p => (p.megaHero ? 800 : 384)}px !important;
  `}

  ${above.tabletLarge`
    height: ${p => (p.megaHero ? 584 : 376)}px !important;
  `}

  ${above.desktop`
    height: ${p => (p.megaHero ? 680 : 464)}px !important;
  `}

 video {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    height: auto !important;
    width: 100% !important;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;

    ${above.tablet`
     transform: ${p => (p.megaHero ? 'translate(-67%, -50%)' : 'translate(-62%, -50%)')};
     width: auto !important;
   `}

    ${above.tabletLarge`
     transform: ${p => (p.megaHero ? 'translate(-58%, -50%)' : 'translate(-60%, -50%)')};
   `}

   ${above.desktop`
     transform: ${p => (p.megaHero ? 'translate(-54%, -50%)' : 'translate(-50%, -50%)')};
   `}

   @media (min-width: 1480px) {
      transform: translate(-50%, -50%);
    }
  }
`

const Gradient = styled.div`
  height: 66px;
  width: 100%;
  position: absolute;
  background: ${p => (p.hideInMobile ? 'none' : `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))`)};

  ${above.tablet`
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    height: 300px;
  `}

  ${above.tabletLarge`
    height: 192px;
  `}
`

const BottomGradient = styled.div`
  height: 266px;
  width: 100%;
  position: relative;
  bottom: 266px;
  background: ${p => (p.hideInMobile ? 'none' : `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0))`)};

  ${above.tablet`
    display: none;
  `}
`

const Wrapper = styled.div`
  display: ${p => (p.hideInMobile ? `none` : `flex`)};
  margin: 0 auto;
  padding: 108px 20px 40px 20px;
  max-width: 1600px;
  background: ${p => (p.mobileGradient === 'True' ? 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25))' : '')};
  ${above.mobile`
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  `}

  ${above.tablet`
    display: flex;
    padding: 60px 0 0 0;
  `}

  ${above.desktop`
    padding: 150px 0 0 0;
  `}
`

const Content = styled.div`
  ${headingOneWave};
  color: ${color.white};
  text-align: center;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.mobileTextAlignment === 'Top' ? 'flex-start' : 'flex-end')};
  align-items: center;
  z-index: 1;
  position: relative;
  margin: 0 auto;

  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${p => (p.heroTextColour === color.white ? color.white : color.costaRed)};
    max-width: 488px;
    margin-top: 0;
    margin-bottom: 0;
    ${headingOneWave}
    text-transform: uppercase;

    ${above.tablet`
      max-width: 600px;
    `}
  }

  > div {
    justify-content: center;
  }

  p {
    color: ${p => (p.heroTextColour === color.white ? color.white : color.costaRed)};
    max-width: 395px;
    padding-top: 26px;
    margin-bottom: 0;
    margin-top: 0;

    ${above.tablet`
    padding-top: 36px;
  `}

    ${above.tabletLarge`
    max-width: 446px;
  `}
  }

  ${above.tablet`
    height: 310px;
    max-width: 600px;
  `}

  ${above.desktop`
    ${p => (p.desktopTextAlignment === 'Center' ? '' : 'margin: 0 0 0 120px;')};
    justify-content: center;
  `}

  ${p =>
    p.megaHero &&
    css`
      ${above.tablet`
        height: 700px;
        max-width: 600px;
      `}
      ${above.tabletLarge`
        height: 500px;
      `}
      ${above.desktop`
        height: 500px;
        max-width: 566px;
      `}
    `}
`
