import React from 'react'
import BlockPromoStandard from './blockPromoStandard'
import BlockPromoTeaser from './blockPromoTeaser'
import BlockPromoCampaign from './blockPromoCampaign'

/**
 * @description This is a wrapper for the Promo Component. It renders
 * the different Promo variations: Standard, Campaign and Teaser.
 */
const BlockPromo = ({ data }) => {
  const {
    style,
    waveBorder,
    standardStyleBgColour,
    campaignStyleBgColour,
    campaignStyleTextColour,
    image,
    imageAlignment = 'right',
    content,
    primaryAction,
    primaryActionLabel,
    primaryActionStyle,
    secondaryAction,
    secondaryActionLabel,
    secondaryActionStyle,
  } = data

  const align = imageAlignment === 'left' ? 'row-reverse' : 'initial'

  switch (style) {
    case 'teaser':
      return (
        <BlockPromoTeaser
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
        />
      )
    case 'campaign':
      return (
        <BlockPromoCampaign
          imageAlignment={align}
          campaignStyleBgColour={campaignStyleBgColour}
          style={style}
          content={content}
          campaignStyleTextColour={campaignStyleTextColour}
          image={image}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
          waveBorder={waveBorder}
        />
      )
    case 'standard':
      return (
        <BlockPromoStandard
          style={style}
          standardStyleBgColour={standardStyleBgColour}
          image={image}
          imageAlignment={align}
          content={content}
          primaryAction={primaryAction}
          primaryActionLabel={primaryActionLabel}
          primaryActionStyle={primaryActionStyle}
          secondaryAction={secondaryAction}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionStyle={secondaryActionStyle}
        />
      )
    default:
      return null
  }
}

export default BlockPromo
