import React from 'react'

import { Box, Flex } from '@chakra-ui/layout'
import { Text, Image } from '@chakra-ui/react'
import { color } from '../../styles'

const Icon = ({ icon }) => {
  const styles = {
    maxHeight: { base: '52px', tablet: '80px' },
    marginBottom: { base: 3, tablet: 4 },
  }

  if (icon.file.contentType === 'image/svg+xml' && icon?.svg?.content) {
    // rendering svg inline lets us style it with css
    return (
      <Flex
        {...styles}
        justifyContent="center"
        dangerouslySetInnerHTML={{ __html: icon.svg.content }}
        sx={{
          svg: {
            width: '100%',
            fill: 'currentColor',
          },
        }}
      />
    )
  }

  return <Image {...styles} marginX="auto" src={icon.file.url} alt="icon" />
}

const BreakoutFactsAndBenefits = ({ data }) => {
  const { icon, colouredText, textColour } = data
  return (
    <Box
      as="article"
      textAlign="center"
      width={{ base: '79%', tablet: '72%', desktop: '100%' }}
      marginX="auto"
      marginY={{ base: 9, tablet: 10 }}
      maxWidth="803"
      textColor={textColour || color.burntOrange}
    >
      <Icon icon={icon} />
      <Text fontFamily={'"Costa Display Wave Bold"'} fontSize={{ base: '20px', tablet: '30px' }} lineHeight="1.1" margin="0" as="p">
        {colouredText}
      </Text>
    </Box>
  )
}

export default BreakoutFactsAndBenefits
