import React from 'react'
import getVideoId from 'get-video-id'
import styled from 'styled-components'

const EmbedVideo = ({ url, name }) => {
  const video = getVideoId(url || ``)

  if (!video.id || !video.service) {
    return null
  }

  const getPlayerUrl = service => {
    if (service === `youtube`) {
      return `https://youtube.com/embed/${video.id}`
    }

    if (service === `vimeo`) {
      return `https://player.vimeo.com/video/${video.id}`
    }

    return null
  }

  return (
    <VideoWrapper>
      <Player
        src={getPlayerUrl(video.service)}
        title={name || null}
        width="640"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </VideoWrapper>
  )
}

export default EmbedVideo

const VideoWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 1rem;
`

const Player = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
