import { color } from '../styles/colors'

const brandColour = colour => {
  if (colour === 'Cloudless Blue') {
    return color.rebrandBlue
  }
  if (colour === 'Nightfall Lilac') {
    return color.secondaryCore
  }
  if (colour === 'Sunset Coral') {
    return color.tertiaryCore
  }
  if (colour === 'Grey') {
    return color.offWhite
  }
  if (colour === 'Costa Red') {
    return color.costaRed
  }
  return color.white
}

export default brandColour
