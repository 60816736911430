/* eslint-disable no-nested-ternary */
import React from 'react'
import ReactPlayer from 'react-player'
import { chakra } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import GatsbyImage from 'gatsby-image'

import { color } from '../../styles/colors'
import RichText from '../richText'
import LinkCta from '../linkCta'

const BlockPromoStandard = ({
  style,
  standardStyleBgColour,
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
}) => {
  const mediaType = image.file.contentType
  const bgColour =
    standardStyleBgColour && standardStyleBgColour === 'Cloudless Blue'
      ? color.rebrandBlue
      : standardStyleBgColour === 'Nightfall Lilac'
      ? color.secondaryCore
      : standardStyleBgColour === 'Sunset Coral'
      ? color.tertiaryCore
      : standardStyleBgColour === 'Grey'
      ? color.offWhite
      : color.white

  const ChakraGatsbyImage = chakra(GatsbyImage)
  const ChakraLinkCta = chakra(LinkCta)
  const ChakraVideoWrapper = chakra('div', {
    position: 'relative',
    top: '0',
    bottom: '0',
    w: '100%',
    h: '100%',
    overflow: 'hidden',
  })
  return (
    <Box
      as="article"
      bg={bgColour}
      my={10}
      mx="auto"
      d={{
        tablet: 'flex',
      }}
      flexWrap={{
        tablet: 'nowrap',
      }}
      flexDir={{
        tablet: imageAlignment,
      }}
      maxW={{
        desktopLarge: '1392px',
      }}
    >
      <Box
        w={{
          base: '100%',
          tablet: '50%',
        }}
        color="greyDarker"
        bg={bgColour}
        px={{
          base: '10%',
          tablet: '5%',
          desktop: '6.5%',
        }}
        py={{
          base: 6,
          mobile: 12,
          tablet: 10,
        }}
        d="flex"
        justifyContent="center"
        flexDir="column"
        minH={{
          base: '300px',
          tablet: '512px',
        }}
        textAlign="center"
        sx={{
          'h1, h2, h3, h4, h5, h6': {
            color: 'costaRed',
            mb: 3,
            w: '100%',
            mt: {
              tablet: '0',
            },
          },
          p: {
            mt: 0,
            color: 'greyDarker',
            w: '100%',
            px: { desktop: 4 },
          },
          a: {
            mb: 0,
          },
        }}
      >
        <RichText data={content} />
        {primaryAction && (
          <ChakraLinkCta
            mt={2}
            link={primaryAction}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="center"
            sx={{
              a: {
                mb: '0 !important',
                bg: `${primaryActionStyle === 'link' ? bgColour : color.costaRed}`,
              },
            }}
          >
            {primaryActionLabel || primaryAction.title}
          </ChakraLinkCta>
        )}
        {secondaryAction && (
          <ChakraLinkCta
            mt={2}
            link={secondaryAction}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="center"
            sx={{
              a: {
                mb: '0 !important',
              },
            }}
          >
            {secondaryActionLabel || secondaryAction.title}
          </ChakraLinkCta>
        )}
      </Box>
      {mediaType.includes('image') && (
        <ChakraGatsbyImage
          fluid={image.fluid}
          alt={image.description}
          promoStyle={style}
          imageAlignment={imageAlignment}
          w={{
            base: 'auto',
            tablet: '50%',
          }}
          m="0"
          minH={{
            base: '300px',
            tablet: '512px',
          }}
          sx={{
            img: {
              mb: '0',
            },
          }}
        />
      )}
      {mediaType.includes('video') && (
        <Box
          d="flex"
          minH={{
            base: '73px',
            tablet: '512px',
          }}
          position="relative"
          w={{
            tablet: '50%',
          }}
          h={{
            tablet: 'auto',
          }}
          sx={{
            video: {
              display: 'block',
              'object-fit': 'cover',
            },
          }}
        >
          <ReactPlayer url={image.file.url} playsinline playing loop muted wrapper={ChakraVideoWrapper} height="100%" width="100%" />
        </Box>
      )}
    </Box>
  )
}

export default BlockPromoStandard
