/* eslint camelcase: off */

import Img from 'gatsby-image'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold, costaText } from '../../styles'
import { renderRichText } from '../../utils/richText'

const AccordionItem = ({ accordion, locale }) => {
  const accordionImage = accordion?.accordionImage?.fluid

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    const openFromAnchor = window.location.hash.substring(1) === accordion.anchor
    const openByDefault = !window.location.hash && accordion.open
    if (openFromAnchor || openByDefault) {
      setIsAccordionOpen(true)
    }
  }, [accordion.anchor, accordion.open])

  const openWithEnterButton = event => event.code === 'Enter' && setIsAccordionOpen(!isAccordionOpen)

  return (
    <Accordion className={isAccordionOpen && 'accordionOpen'} key={accordion.anchor} tabIndex={-1} onKeyDown={openWithEnterButton}>
      <Accordion.Title
        role="button"
        tabIndex={0}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        aria-controls={accordion.anchor}
        aria-expanded={isAccordionOpen}
      >
        {accordion.title}
        <Accordion.Title.Icon className={isAccordionOpen && 'accordionOpen'} />
      </Accordion.Title>
      <Accordion.Content id={accordion.anchor} className={isAccordionOpen && 'accordionOpen'}>
        {accordionImage && <GatsbyImage fluid={accordionImage} />}
        <Accordion.RichTextContainer>{renderRichText(accordion.content, locale)}</Accordion.RichTextContainer>
      </Accordion.Content>
    </Accordion>
  )
}

const AccordionBlock = ({ data }) => {
  const { title, anchor, accordionItem, node_locale } = data

  return (
    <Container>
      {title && <Title id={anchor}>{title}</Title>}
      {accordionItem &&
        accordionItem.map(accordion => (
          <AccordionItem
            accordion={accordion}
            accordionLength={accordionItem.length}
            // eslint-disable-next-line camelcase
            locale={node_locale}
          />
        ))}
    </Container>
  )
}

const Container = styled.article`
  background: ${color.white};
  padding: 40px 5%;
  max-width: 608px;
  margin: 0 auto;

  ${above.tablet`
  
    max-width: 672px;
  `}

  ${above.tabletLarge`
    max-width: 824px;
    padding: 40px 9% 40px 8%;
  `} 
  
  ${above.desktop`
    max-width: 1392px;
    padding: 40px 118px 40px;
  `}
`

/* Add above to match Zeplin designs
  background: ${color.white};
  max-width: 300px;
  margin: 0px auto 48px;
  padding: 8px 0 8px;
  object-fit: contain;
  ${above.tablet`
    max-width: 680px;
  `}
  ${above.tabletLarge`
    max-width: 824px;
    padding: 40px auto 40px;
  `} 
  
  ${above.desktop`
    max-width: 1280px;
    margin: 0px auto 40px;
    padding: 40px 104px 40px;
  `}
*/

const Accordion = styled.div`
  margin: 0 auto 8px auto;

  &:hover {
    background: ${color.offWhite};
    transition: 0.3s;
  }

  &.accordionOpen {
    background: ${color.offWhite};
  }
`
/* Add above to match Zeplin designs
  margin: 0 auto 8px auto;
  ${above.tablet`
    margin-bottom: 0px;
  `}
  padding: 8px 16px 8px;
  ${above.desktop`
    max-width: 1064px;
    padding: 8px 32px;
  `}
  &:focus,
  &:hover {
    background: ${color.offWhite};
    transition: 0.3s;
  }
  &.accordionOpen {
    background: ${color.offWhite};
  }
  */

Accordion.Title = styled.label`
  color: ${color.costaRed};
  ${costaTextBold}
  font-size: 18px;
  line-height: 1.1;
  display: block;
  position: relative;
  padding: 8px 36px 8px 0;
  cursor: pointer;
  user-select: none;
  width: 90%;
  margin: 0 auto;

  ${above.tablet`
    font-size: 24px;
    line-height: 1.2;
    margin: 0 24px;
    padding: 8px 36px 0 0;
  `}

  ${above.desktop`
    padding-top: 16px;
    margin: 0 32px;
    padding: 8px 36px 8px 0;
  `}

  &:hover {
    color: ${color.lightRed};
    transition: 0.3s;
  }
`

Accordion.Title.Icon = styled.span`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${color.costaRed};
  position: absolute;
  right: 0;
  top: 16px;
  transition: transform 0.3s;
  transform: rotate(180deg);
  &.accordionOpen {
    transform: rotate(0);
  }

  ${above.tablet`
    top: 24px;
    right: -16px;
  `}

  ${above.desktop`
    top: 24px;
    right: -48px;
  `}
`

Accordion.Content = styled.div`
  display: none;
  overflow: auto;
  background-color: ${color.white};
  overflow: hidden;

  strong {
    ${costaTextBold}
    font-weight: normal !important;
  }

  a {
    ${costaText}
    color: ${color.costaRed};

    &:hover {
      color: ${color.lightRed};
      transition: 0.3s;
    }
  }

  ul {
    padding-inline-start: 40px;
    padding-left: 24px;
    &:first-child {
      margin-top: 16px;
      ${above.tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  ol {
    padding-inline-start: 40px;
    &:first-child {
      margin-top: 16px;
      ${above.tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  h1,
  h2 {
    color: ${color.costaRed};
  }

  .inline-right {
    float: right;
    max-width: 500px;
    padding: 0 0 40px 40px;
  }

  .inline-left {
    float: left;
    max-width: 500px;
    padding: 0 40px 40px 0;
  }

  &.accordionOpen {
    display: block;
    height: auto;
    background: ${color.offWhite};
  }
`

Accordion.Checkbox = styled.input`
  display: none;

  ~ ${Accordion.Content} {
    padding: 0;
  }
`

const Title = styled.h3`
  color: ${color.costaRed};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
`

/* Add above to match Zeplin designs
  color: ${color.costaRed};
  padding: 0px 16px;
  margin: 24px 0;
  ${above.desktop`
    padding: 0px 32px;
    margin: 24px 0;
  `}
*/

const GatsbyImage = styled(Img)`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-top: 8px;

  ${above.tablet`
    float: left;
    width: 45%;
    margin: 16px 24px;
  `}

  ${above.desktop`
    width: 25%;
    margin: 16px 32px;
  `}
`

Accordion.RichTextContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  ${above.tablet`
    margin: 0 24px;
  `}

  ${above.desktop`
    margin: 0 32px;
  `}
`

export default AccordionBlock
