const typography = {
  // TODO lineHeights
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/typography.ts

  fonts: {
    costaDisplayWaveBold: 'Costa Display Wave Bold',
    costaTextBold: 'Costa Text Bold',
    costaTextLight: 'Costa Text Light',
    costaText: 'Costa Text Regular',

    /* Currently not being used

    costaDisplayWaveLight: 'Costa Display Wave Light',
    costaDisplayWaveRegular: 'Costa Display Wave Regular',
    costaDisplayBold: 'Costa Display Bold',
    costaDisplayLight: 'Costa Display Light',
    costaDisplayRegular: 'Costa Display Regular',
    */
  },

  // TODO textStyles are currently broken!
  // https://github.com/chakra-ui/chakra-ui/issues/3884

  textStyles: {
    // TODO h1, h2, h3, h4, h5, h6, body
    h1: {
      fontFamily: 'costaDisplayWaveBold',
      fontSize: { base: '33px', tablet: '60px' },
      lineHeight: { base: '36px', tablet: '66px' },
    },
  },
}

export default typography
