import React from 'react'
import { chakra } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import GatsbyImage from 'gatsby-image'

import RichText from '../richText'
import LinkCta from '../linkCta'
import { color } from '../../styles'

const BlockPromoTeaser = ({
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
}) => {
  const { fluid, description } = image
  const ChakraGatsbyImage = chakra(GatsbyImage)
  const ChakraLinkCta = chakra(LinkCta)
  return (
    <Box
      as="article"
      bg="white"
      pt="40px"
      px={{
        base: '5%',
        desktopLarge: '118px',
      }}
      mx={{
        base: '2.5%',
        tablet: '6%',
        desktop: '9%',
        desktopLarge: '4%',
      }}
      d={{
        tablet: 'flex',
      }}
      flexDir={{
        tablet: imageAlignment === 'row-reverse' ? 'row' : 'row-reverse',
      }}
      flexWrap={{
        tablet: 'wrap',
        desktop: 'nowrap',
      }}
      alignItems="flex-start"
      maxW={{
        desktop: '1274px',
      }}
    >
      <ChakraGatsbyImage
        m={{
          base: '0 0 21px 0',
          desktop: '0 2rem 1rem 56px',
        }}
        ml={{
          desktop: imageAlignment === `row-reverse` ? '0' : '2rem',
        }}
        mr={{
          desktop: imageAlignment !== `row-reverse` ? '0' : '56px',
        }}
        w={{
          tablet: '100%',
          desktop: '70%',
          desktopLarge: '50%',
        }}
        minH={{
          tablet: '200px',
        }}
        maxH={{
          desktopLarge: '220px',
        }}
        fluid={fluid}
        alt={description}
        imageAlignment={imageAlignment}
      />
      <Box
        w="100%"
        color="greyDarker"
        pb={{
          base: 2,
          desktopLarge: 0,
        }}
        sx={{
          'h1, h2, h3, h4, h5, h6': {
            color: 'costaRed',
            mb: 1,
            mt: 0,
            w: '100%',
          },
          p: {
            m: 0,
            color: 'greyDarker',
            w: '100%',
            p: { desktop: 0 },
          },
        }}
      >
        <RichText data={content} />
        {primaryAction && (
          // Custom colour prop to be changed if we make the CTA colour authorable for the block promo.
          <ChakraLinkCta link={primaryAction} ctaStyle={primaryActionStyle || 'button'} alignment="left" mt={2} customColor={color.costaRed}>
            {primaryActionLabel || primaryAction.title}
          </ChakraLinkCta>
        )}
        {secondaryAction && (
          <ChakraLinkCta link={secondaryAction} ctaStyle={secondaryActionStyle || 'link'} alignment="left" mt={2}>
            {secondaryActionLabel || secondaryAction.title}
          </ChakraLinkCta>
        )}
      </Box>
    </Box>
  )
}

export default BlockPromoTeaser
