import React from 'react'
import styled from 'styled-components'
import { above } from '../../styles'
import getDeviceType from '../../helpers/getDeviceType'

// @ts-ignore
import iosStoreImage from '../../images/apple-store.png'
// @ts-ignore
import androidStoreImage from '../../images/google-store.jpeg'

interface AppLinkProps {
  url: string
  image: string
  linkTitle: string
}

interface AppLinksProps {
  url: string
  linkTitle: string
}

const AppLink = ({ url, image, linkTitle }: AppLinkProps) => (
  <Link href={url} target="_blank" title={linkTitle}>
    <Image src={image} alt="" />
  </Link>
)

const AppLinks = ({ url, linkTitle }: AppLinksProps) => {
  if (typeof window === 'undefined') {
    return null
  }

  const deviceType = getDeviceType(window.navigator.userAgent)
  const showIos = deviceType === 'ios' || deviceType === 'other'
  const showAndroid = deviceType === 'android' || deviceType === 'other'

  return (
    <AppLinksWrapper>
      {showIos && <AppLink url={url} image={iosStoreImage} linkTitle={linkTitle} />}
      {showAndroid && <AppLink url={url} image={androidStoreImage} linkTitle={linkTitle} />}
    </AppLinksWrapper>
  )
}

const AppLinksWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Link = styled.a`
  height: 40px;

  & + a {
    margin-left: 8px;

    ${above.desktop`
    margin-left: 16px;
  `}
  }
`

const Image = styled.img`
  object-fit: contain;
  height: 100%;
`

export default AppLinks
